<template lang="">
    <div>
        <List />
    </div>
</template>
<script>
export default {
    name:"find-property",
    components:{
        List : () => import("@/components/property/List")
    }
}
</script>
